import { Box, Chip, Grid2, IconButton, Paper, Typography } from "@mui/material";
import { InvoiceStatusEnum, Organization } from "@syadem/kairos-subscription-js";
import { theme } from "../../../layout/Theme";
import { useI18n } from "../../../hooks/useI18n";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { dayjs, localizedDate } from "../../../../utils/dayjs";
import DownloadIcon from "@mui/icons-material/Download";
import { orderBy } from "lodash-es";

export function Invoices({ subscriptionOrganization }: { subscriptionOrganization: Organization }) {
  const { t, locale } = useI18n();

  const invoices = orderBy(
    subscriptionOrganization.invoices.filter(
      (i) => i.amount > 0 && (i.status == InvoiceStatusEnum.Open || i.status == InvoiceStatusEnum.Paid),
    ),
    "date",
    "desc",
  );

  const statusColor: { [status: string]: "success" | "warning" | "error" } = {
    open: "error",
    paid: "success",
    uncollectible: "warning",
    void: "warning",
  };

  return (
    <Paper
      sx={{
        maxWidth: "sm",
        marginX: "auto",
        marginTop: 1,
        overflow: "hidden",
        border: `solid 1px ${theme.palette.neutral[200]}`,
        paddingX: 4,
        paddingY: 2,
      }}
      elevation={0}
    >
      <Box display="flex" alignItems="center" mb={1}>
        <ReceiptIcon sx={{ color: theme.palette.neutral["600"] }} />
        <Typography fontWeight={500} color={theme.palette.neutral["600"]} fontSize={18} ml={1}>
          {t("subscriptions.invoices")}
        </Typography>
      </Box>
      {invoices.length > 0 ? (
        invoices.map((invoice) => (
          <Grid2 container key={invoice.id} alignItems="center">
            <Grid2 size={4}>
              <Typography color={theme.palette.neutral["600"]}>{invoice.number}</Typography>
            </Grid2>
            <Grid2 size={3}>
              <Typography color={theme.palette.neutral["600"]}>{localizedDate(dayjs(invoice.date), locale)}</Typography>
            </Grid2>
            <Grid2 size={2}>
              <Typography color={theme.palette.neutral["600"]}>{invoice.amount / 100}€</Typography>
            </Grid2>
            <Grid2 size={2}>
              <Chip
                label={t(`subscriptions.invoiceStatuses.${invoice.status}`)}
                color={statusColor[invoice.status]}
                variant="outlined"
                size="small"
              />
            </Grid2>
            <Grid2 container justifyContent="flex-end" size={1}>
              <IconButton size="small" onClick={() => window.open(invoice.url)}>
                <DownloadIcon sx={{ color: theme.palette.neutral["600"] }} fontSize="small" />
              </IconButton>
            </Grid2>
          </Grid2>
        ))
      ) : (
        <Typography color={theme.palette.neutral["600"]} textAlign="center">
          {t("subscriptions.noInvoice")}
        </Typography>
      )}
    </Paper>
  );
}
