import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import * as Sentry from "@sentry/react";
import "../src/assets/index.css";
import { App } from "./app";

if (import.meta.env.PROD) {
  // Note that release and environment values configured here have to match sent
  // values uploaded to Sentry during build (see vite.config.ts)
  Sentry.init({
    dsn: "https://042ea459ded6491687475c655952fac2@o477337.ingest.sentry.io/6501869",
    integrations: [],
    release: import.meta.env.VITE_APP_CI_COMMIT_SHA,
    environment: import.meta.env.VITE_APP_CI_COMMIT_BRANCH,
    ignoreErrors: ["Non-Error promise rejection captured"],

    tracesSampleRate: 0,
    sampleRate: 0,
    enableTracing: false,
  });
}

const app = new App({
  oidcUrl: import.meta.env.VITE_AUTH_URL,
  kairosProApiBasePath: import.meta.env.VITE_BACKEND_URL,
  kairosTeamApiBasePath: import.meta.env.VITE_BACKEND_TEAM_URL,
  kairosCertApiBasePath: import.meta.env.VITE_CERT_URL,
  kairosSubscriptionApiBasePath: import.meta.env.VITE_SUBSCRIPTION_URL,
  subscriptionDefaultProductId: import.meta.env.VITE_DEFAULT_PRODUCT_ID,
  sadApiBasePath: import.meta.env.VITE_SAD_URL,
  arianeApiBasePath: import.meta.env.VITE_ARIANE_URL,
  nuvaUrl: import.meta.env.VITE_NUVA_URL,
  mentorUrl: import.meta.env.VITE_MENTOR_URL,
});

app.start();
