import { UserManager } from "oidc-client-ts";
import { AppRouter } from "../appConfig";
import { Apis } from "../network/apis";
import { Queries } from "../queries";
import { AppStore } from "../store";
import { BuildPscAccountLinkingUrlService } from "./buildPscAccountLinkingUrlService";
import { CertifyVaccinationActService } from "./CertifyVaccinationService";
import { CreateHealthRecordService } from "./createHealthRecordService";
import { CreateOrganizationService } from "./CreateOrganizationService";
import { CreateSubscriptionService } from "./CreateSubscriptionService";
import { CreateTeamService } from "./CreateTeamService";
import { CreateVaccinationActService } from "./CreateVaccinationActService";
import { DeleteVaccinationActService } from "./DeleteVaccinationActService";
import { EditUserService } from "./editUserService";
import { RedirectToLiberalOrTeamService } from "./RedirectToLiberalOrTeamService";
import { Service, ServicePayload } from "./service";
import { ShareWithCitizenByMailService } from "./ShareWithCitizenByMailService";
import { SignInService } from "./signInService";
import { SignOutService } from "./signOutService";
import { UpdateProfessionalAccountVisibilityService } from "./UpdateProfessionalAccountVisibilityService";
import { UpdateTeamVisibilityService } from "./UpdateTeamVisibilityService";
import { UpdateVaccinationActService } from "./UpdateVaccinationActService";

type ServiceCollection = {
  // We don't care about the return type of the service here
  [key: string]: Service<ServicePayload, any>;
};

export interface Services extends ServiceCollection {
  signIn: SignInService;
  signOut: SignOutService;
  editUser: EditUserService;
  redirectToLiberalOrTeam: RedirectToLiberalOrTeamService;
  shareWithCitizenByMail: ShareWithCitizenByMailService;
  updateProfessionalAccountVisibility: UpdateProfessionalAccountVisibilityService;
  updateTeamVisibility: UpdateTeamVisibilityService;
  createTeam: CreateTeamService;
  createOrganization: CreateOrganizationService;
  createHealthRecord: CreateHealthRecordService;
  buildPscAccountLinkingUrl: BuildPscAccountLinkingUrlService;
  createVaccinationAct: CreateVaccinationActService;
  updateVaccinationAct: UpdateVaccinationActService;
  deleteVaccinationAct: DeleteVaccinationActService;
  certifyVaccinationAct: CertifyVaccinationActService;
}

export function initServices({
  store,
  oidcManager,
  router,
  apis,
  queries,
  createSubscription,
}: {
  store: AppStore;
  oidcManager: UserManager;
  router: AppRouter;
  apis: Apis;
  queries: Queries;
  createSubscription?: CreateSubscriptionService;
}): Services {
  return {
    signIn: new SignInService(store, window, oidcManager, router, queries.authStateQuery),
    signOut: new SignOutService(window, oidcManager),
    editUser: new EditUserService(window, oidcManager),
    redirectToLiberalOrTeam: new RedirectToLiberalOrTeamService(router, localStorage, apis.team.organizationApi),
    shareWithCitizenByMail: new ShareWithCitizenByMailService(apis.pro.recordSharingApi, apis.team.recordSharingApi),
    updateProfessionalAccountVisibility: new UpdateProfessionalAccountVisibilityService(
      store,
      apis.pro.professionalAccountApi,
    ),
    updateTeamVisibility: new UpdateTeamVisibilityService(store, apis.team.teamApi),
    createTeam: new CreateTeamService(store, apis.team.teamApi),
    createOrganization: new CreateOrganizationService(store, apis.team.organizationApi, createSubscription),
    createHealthRecord: new CreateHealthRecordService(store, apis.pro.healthRecordApi, apis.team.healthRecordApi),
    buildPscAccountLinkingUrl: new BuildPscAccountLinkingUrlService(window, oidcManager),
    createVaccinationAct: new CreateVaccinationActService(
      apis.pro.vaccinationActApi,
      apis.team.vaccinationActApi,
      store,
    ),
    updateVaccinationAct: new UpdateVaccinationActService(
      apis.pro.vaccinationActApi,
      apis.team.vaccinationActApi,
      store,
    ),
    deleteVaccinationAct: new DeleteVaccinationActService(
      apis.pro.vaccinationActApi,
      apis.team.vaccinationActApi,
      store,
    ),
    certifyVaccinationAct: new CertifyVaccinationActService(
      apis.pro.vaccinationActApi,
      apis.team.vaccinationActApi,
      store,
    ),
  };
}
