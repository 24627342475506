import { useCallback, useEffect, useState } from "react";
import { useQueries } from "../providers/Dependencies";
import { useAppContext } from "./useAppContext";

export function useFetchHealthRecordVaccinations(id: string): {
  isLoading: boolean;
  error?: Response | Error;
  refreshVaccinations: () => void;
} {
  const { organizationId, teamId } = useAppContext();

  const [error, setError] = useState<Response | Error | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [refreshCounter, setRefreshCounter] = useState(0);

  const queries = useQueries();

  const refreshVaccinations = useCallback(() => {
    setRefreshCounter((prev) => prev + 1);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (organizationId && teamId) {
          await queries.teamVaccinationActsQuery.call(organizationId, teamId, id);
        } else {
          await queries.proVaccinationActsQuery.call(id);
        }
      } catch (error) {
        console.error("Error in useFetchHealthRecordVaccinations: ", error);
        if (error instanceof Response || error instanceof Error) {
          setError(error);
        } else {
          throw error;
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, [id, refreshCounter, organizationId, teamId, queries.teamVaccinationActsQuery, queries.proVaccinationActsQuery]);

  return { isLoading, error, refreshVaccinations };
}
