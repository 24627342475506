import { ThemeProvider } from "@emotion/react";
import { Box } from "@mui/material";
import { Daphne, initDaphne } from "@syadem/daphne-js";
import {
  InvoiceApi,
  SubscriptionApi,
  Configuration as SubscriptionConfiguration,
  OrganizationApi as SubscriptionOrganizationApi,
} from "@syadem/kairos-subscription-js";
import { UserManager } from "oidc-client-ts";
import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { AppRouter, Config, CountryConfig, SubscriptionInterface } from "../src/appConfig";
import { Apis, initApis } from "../src/network/apis";
import { initQueries, Queries } from "../src/queries";
import { SubscriptionOrganizationsQuery } from "../src/queries/SubscriptionOrganizationsQuery";
import { SubscriptionCheckoutUrlQuery } from "../src/queries/subscriptionCheckoutUrlQuery";
import { SubscriptionUpcomingInvoiceQuery } from "../src/queries/subscriptionInvoicesQuery";
import { ResultNotifier } from "../src/resultNotifier";
import { initServices, Services } from "../src/services";
import { ApplySubscriptionDiscountService } from "../src/services/ApplySubscriptionDiscountService";
import { CancelSubscriptionService } from "../src/services/CancelSubscriptionService";
import { CreateSubscriptionService } from "../src/services/CreateSubscriptionService";
import { ResumeSubscriptionService } from "../src/services/ResumeSubscriptionService";
import { StopSubscriptionCancellationService } from "../src/services/StopSubscriptionCancellationService";
import { UpdateSubscriptionQuantityService } from "../src/services/UpdateSubscriptionQuantityService";
import { ServiceBus } from "../src/services/serviceBus";
import { AppStore, initStore } from "../src/store";
import { Loading } from "../src/ui/components/shared/Loading";
import { theme } from "../src/ui/layout/Theme";
import { LoadingAppFailed } from "../src/ui/pages/LoadingAppFailed";
import { DependenciesProvider } from "../src/ui/providers/Dependencies";
import { I18nProvider } from "../src/ui/providers/I18nProvider";
import { routes } from "../src/ui/routes";
import { datamatrixProcessor } from "../src/utils/datamatrix";
import { SecurityNumberInput } from "./components/SecurityNumberInput";
import { validateSecurityNumber } from "./utils/securityNumberValidator";
import * as Sentry from "@sentry/react";
import "dayjs/locale/en-gb";
import "dayjs/locale/fr";

export class App {
  private readonly router: AppRouter;
  private readonly store: AppStore;
  private readonly apis: Apis;
  private readonly services: Services;
  private readonly queries: Queries;
  private readonly serviceBus: ServiceBus;
  private readonly resultNotifier: ResultNotifier;
  private readonly countryConfig: CountryConfig;
  private daphne?: Daphne;

  constructor(private readonly config: Config) {
    const {
      oidcUrl,
      kairosProApiBasePath,
      kairosTeamApiBasePath,
      kairosCertApiBasePath,
      kairosSubscriptionApiBasePath,
      subscriptionDefaultProductId,
      sadApiBasePath,
      arianeApiBasePath,
    } = config;

    const oidcManager = initOidcManager(oidcUrl);
    const currentToken = async () => (await oidcManager.getUser())?.access_token ?? "";

    this.store = initStore();
    this.resultNotifier = new ResultNotifier(this.store);

    this.apis = initApis({
      kairosProApiBasePath,
      kairosTeamApiBasePath,
      kairosCertApiBasePath,
      sadApiBasePath,
      arianeApiBasePath,
      accessToken: currentToken,
    });

    this.router = createBrowserRouter(routes(true));

    if (!subscriptionDefaultProductId || !kairosSubscriptionApiBasePath) {
      throw new Error("At least one subscription related env variables is missing");
    }

    const kairosSubscriptionApiConfig = new SubscriptionConfiguration({
      basePath: kairosSubscriptionApiBasePath,
      accessToken: currentToken,
    });

    const subscriptionApis: SubscriptionInterface["apis"] = {
      subscriptionApi: new SubscriptionApi(kairosSubscriptionApiConfig),
      organizationApi: new SubscriptionOrganizationApi(kairosSubscriptionApiConfig),
      invoiceApi: new InvoiceApi(kairosSubscriptionApiConfig),
    };

    this.countryConfig = {
      defaultLocale: "fr",
      availableLocales: ["fr", "en-gb"],
      defaultCountryCode: "FRA",
      zipCode: {
        length: 5,
        regex: /^(?:0[1-9]|[1-9]\d|9[0-8])\d{3}$/,
        placeholder: "ex : 33000",
        sharingPlaceholder: "ex : Bordeaux, 33000, ...",
        requiredForHealthRecord: true
      },
      phoneNumber: {
        minLength: 10,
        maxLength: 18,
        regex: /^(?:(?:\+|00)33|0)\s*[1-7](?:[\s.-]*\d{2}){4}$/,
        placeholder: "ex : 0611223344",
      },
      securityNumber: {
        validator: validateSecurityNumber,
        Input: SecurityNumberInput,
      },
      subscription: {
        defaultProductId: subscriptionDefaultProductId,
        apis: subscriptionApis,
        queries: {
          subscriptionOrganizationsQuery: new SubscriptionOrganizationsQuery(
            this.store,
            subscriptionApis.organizationApi,
          ),
          subscriptionCheckoutUrlQuery: new SubscriptionCheckoutUrlQuery(subscriptionApis.organizationApi),
          subscriptionUpcomingInvoiceQuery: new SubscriptionUpcomingInvoiceQuery(subscriptionApis.invoiceApi),
        },
        services: {
          cancelSubscription: new CancelSubscriptionService(this.store, subscriptionApis.subscriptionApi),
          stopSubscriptionCancellation: new StopSubscriptionCancellationService(
            this.store,
            subscriptionApis.subscriptionApi,
          ),
          createSubscription: new CreateSubscriptionService(this.store, subscriptionApis.subscriptionApi),
          updateSubscritpionQuantity: new UpdateSubscriptionQuantityService(
            this.store,
            subscriptionApis.subscriptionApi,
          ),
          resumeSubscription: new ResumeSubscriptionService(this.store, subscriptionApis.subscriptionApi),
          applySubscriptionDiscount: new ApplySubscriptionDiscountService(this.store, subscriptionApis.subscriptionApi),
        },
      },
    };

    this.queries = initQueries({
      store: this.store,
      apis: this.apis,
    });

    this.services = initServices({
      store: this.store,
      oidcManager,
      router: this.router,
      apis: this.apis,
      queries: this.queries,
      createSubscription: this.countryConfig.subscription?.services.createSubscription,
    });

    this.serviceBus = new ServiceBus(
      { ...this.services, ...this.countryConfig.subscription?.services },
      this.resultNotifier,
      this.router,
    );
  }

  async start() {
    const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

    root.render(
      <React.StrictMode>
        <I18nProvider defaultLocale={this.countryConfig.defaultLocale}>
          <Box sx={{ width: "100vw", height: "100vh" }}>
            <Loading title={"Authentification en cours..."} />
          </Box>
        </I18nProvider>
      </React.StrictMode>,
    );

    try {
      const [daphne] = await Promise.all([
        initDaphne({ baseUrl: this.config.nuvaUrl, locale: this.countryConfig.defaultLocale }),
        this.services.signIn.call(),
      ]);
      this.daphne = daphne;
    } catch (error) {
      Sentry.captureException(error);

      root.render(
        <React.StrictMode>
          <I18nProvider defaultLocale={this.countryConfig.defaultLocale}>
            <LoadingAppFailed />
          </I18nProvider>
        </React.StrictMode>,
      );
      return;
    }

    const authState = this.store.getState().authState;

    if (authState.type != "signed-in") {
      root.render(
        <React.StrictMode>
          <I18nProvider defaultLocale={this.countryConfig.defaultLocale}>
            <LoadingAppFailed />
          </I18nProvider>
        </React.StrictMode>,
      );
      return;
    }

    root.unmount();

    ReactDOM.createRoot(document.getElementById("root")!).render(
      <React.StrictMode>
        <DependenciesProvider
          dependencies={{
            store: this.store,
            arianeApi: this.apis.arianeApi,
            queries: this.queries,
            serviceBus: this.serviceBus,
            sadApi: this.apis.sadApi,
            daphne: this.daphne,
            apis: this.apis,
            config: this.config,
            countryConfig: this.countryConfig,
            processDatamatrixCode: datamatrixProcessor(this.daphne),
          }}
        >
          <ThemeProvider theme={theme}>
            <I18nProvider defaultLocale={this.countryConfig.defaultLocale}>
              <RouterProvider router={this.router} />
            </I18nProvider>
          </ThemeProvider>
        </DependenciesProvider>
      </React.StrictMode>,
    );
  }
}

const OIDC_CLIENT = "kairos-pro-front";

function initOidcManager(oidcUrl: string) {
  const oidcManager = new UserManager({
    client_id: OIDC_CLIENT,
    authority: oidcUrl,
    redirect_uri: window.location.href,
    loadUserInfo: true,
  });
  oidcManager.events.addAccessTokenExpired((error: unknown) => {
    console.debug("Access token expired error", error);
  });
  oidcManager.events.addUserSignedOut(() => {
    alert("User signed out");
  });
  oidcManager.events.addSilentRenewError((error: unknown) => {
    console.debug("Silent renew error", error);
    // reload window to force user to sign in again
    window.location.reload();
  });
  return oidcManager;
}
